@import url("https://fonts.googleapis.com/css?family=Courier+Prime:700|Roboto:700|Roboto&display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  cursor: default;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}
#link{
  color: #ededed;
  text-decoration: none;
}

button {
  font-family: "Roboto", sans-serif;
}

legend {
  font-family: "Roboto", sans-serif;
  font-size: x-small;
  font-weight: bold;
  margin: 0.3em;
}

fieldset {
  border: 1px solid darkgray;
  padding-bottom: 0.3em;
  margin-top: -0.5em;
}

#paddle-mode-buttons {
  visibility: hidden;
}

html, body {
  height: 100%;
  width: 100vw;
  background: #eee;
}

#root {
  width: 100vw;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#header {
  min-height: 50px;
  min-height: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 15px;
  background: #333;
  font-family: "Roboto", sans-serif;
  color: #eee;
  z-index: 1000;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.45);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.45);
  font-size: 2.5em;
}

#header #title {
  font-weight: bold;
  text-transform: uppercase;
}

#header #social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
  margin-right: 10px;
}

#header #social-links i {
  color: #ccc;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 2rem;
}

#header #social-links i:hover {
  color: gold;
}

#header #social-links div {
  height: auto;
}

#header #social-links div img {
  width: 40px;
  height: 40px;
  opacity: 0.3;
}

#header #social-links div img:hover {
  -webkit-animation-name: socialLinkHover;
          animation-name: socialLinkHover;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

#header #social-links div#twitter img {
  -webkit-filter: invert(90%);
          filter: invert(90%);
}

#main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 95vh;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 100vw;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  overflow-x: hidden;
  position: relative;
  left: 0px;
}

#main-content .sidebar#left {
  background: #eee;
  -webkit-box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.25);
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40%;
  height: calc(100% - 5.1em);
  max-width: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 50px;
  left: 0px;
  z-index: 100;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

#main-content .sidebar#left.hide {
  left: calc(-40% + 40px);
  top: 50px;
  overflow-y: hidden;
}

#main-content .sidebar#left.hide #sidebar-container #sidebar-content #info {
  opacity: 0%;
  overflow-y: hidden;
}

#main-content .sidebar#left.hide #sidebar-container #info-icon {
  top: 0px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

#main-content .sidebar#left #sidebar-container {
  height: 100%;
  width: 100%;
}

#main-content .sidebar#left #sidebar-container .navbar {
  font-size: 1em;
  line-height: 1em;
  width: 100%;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: uppercase;
  font-weight: 550;
}

#main-content .sidebar#left #sidebar-container .navbar .nav-item {
  padding: 10px;
  width: 30%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  border-bottom: 2px solid transparent;
}

#main-content .sidebar#left #sidebar-container .navbar .nav-item:hover {
  background: rgba(0, 0, 0, 0.1);
  border-color: #999;
}

#main-content .sidebar#left #sidebar-container .navbar .nav-item.selected {
  border-color: #508090;
}

#main-content .sidebar#left #sidebar-container .navbar .nav-item#nav-play {
  display: none;
}

#main-content .sidebar#left #sidebar-container #info-icon {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 7px;
  right: 6px;
  z-index: 1010;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

#main-content .sidebar#left #sidebar-container #info-icon:hover i {
  color: goldenrod;
}

#main-content .sidebar#left #sidebar-container #info-icon i {
  color: #333;
  font-size: 1.7rem;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

#main-content .sidebar#left #sidebar-container #sidebar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-item-align: center;
      align-self: center;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #playerAndLegend {
  padding: 2em;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-y: scroll;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #playerAndLegend #legend, #main-content .sidebar#left #sidebar-container #sidebar-content #playerAndLegend #playMorseInput, #main-content .sidebar#left #sidebar-container #sidebar-content #playerAndLegend span#note {
  margin-bottom: 1em;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #playerAndLegend span#note {
  font-family: "Courier", monospace;
  color: #555;
  display: inline-block;
  width: 65%;
  text-align: center;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2.5em;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5em;
  opacity: 1;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info h1, #main-content .sidebar#left #sidebar-container #sidebar-content #info h2 {
  margin-bottom: 0.3em;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info .bold {
  display: inline-block;
  font-size: 1.15em;
  font-weight: bold;
  margin-bottom: 0.1em;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info p {
  margin-bottom: 2em;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info p span.ditdah {
  font-family: "Courier", monospace;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info img {
  width: 50%;
  image-rendering: optimizeSpeed;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info a:visited {
  color: #333;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info a:hover, #main-content .sidebar#left #sidebar-container #sidebar-content #info i:hover {
  color: goldenrod;
}

#main-content .sidebar#left #sidebar-container #sidebar-content #info i {
  font-size: 1.2rem;
  line-height: 1rem;
}

#main-content #main-interface {
  background: whitesmoke;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 60%;
  position: relative;
  left: 40%;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

#main-content #main-interface.expandLeft {
  left: 40px;
  width: calc(100% - 40px);
}

#main-content #main-interface #mainOptions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  overflow-x: scroll;
  width: 100%;
  padding: 10px;
  background: rgba(112, 128, 144, 0.3);
  -webkit-box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  z-index: 500;
}

#main-content #main-interface #mainOptions #options-left, #main-content #main-interface #mainOptions #options-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#main-content #main-interface #mainOptions #options-left .mode-picker, #main-content #main-interface #mainOptions #options-right .mode-picker {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#main-content #main-interface #mainOptions #options-left .mode-picker div, #main-content #main-interface #mainOptions #options-right .mode-picker div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 3px;
  height: 100%;
  margin-bottom: 5px;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #title, #main-content #main-interface #mainOptions #options-right .mode-picker #title {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 10em;
  font-weight: bold;
  font-size: 1.15em;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #title span#range, #main-content #main-interface #mainOptions #options-right .mode-picker #title span#range {
  display: inline-block;
  padding-left: 5px;
  font-size: 0.8em;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #buttons, #main-content #main-interface #mainOptions #options-right .mode-picker #buttons {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #input input, #main-content #main-interface #mainOptions #options-right .mode-picker #input input {
  width: 50px;
  height: 1.5rem;
  border: 1px solid #ddd;
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  text-align: center;
  border-radius: 3px;
  font-size: 0.8rem;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #input select, #main-content #main-interface #mainOptions #options-right .mode-picker #input select {
  height: 1.4rem;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #input button, #main-content #main-interface #mainOptions #options-right .mode-picker #input button {
  width: 1.4em;
  height: 1.4em;
  border-radius: 3px;
  font-size: 1em;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #input button i, #main-content #main-interface #mainOptions #options-right .mode-picker #input button i {
  position: relative;
  left: -6px;
  top: -2px;
  font-size: 1.1em;
  font-weight: bold;
}

#main-content #main-interface #mainOptions #options-left .mode-picker button, #main-content #main-interface #mainOptions #options-right .mode-picker button {
  background: #eee;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  border-radius: 3px;
  border: 0px;
  padding: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.9em;
  color: #333;
}

#main-content #main-interface #mainOptions #options-left .mode-picker button.selected, #main-content #main-interface #mainOptions #options-right .mode-picker button.selected {
  -webkit-box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3), inset 0px -1px 1px white;
          box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3), inset 0px -1px 1px white;
}

#main-content #main-interface #mainOptions #options-left .mode-picker #title {
  width: 5em;
}

#footer {
  width: 100%;
  height: 2em;
  padding: 0.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #333;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  color: #eee;
  z-index: 1000;
}

#footer i {
  font-size: 1.3em;
  color: #ccc;
}

#footer i:hover {
  color: gold;
}

h2 {
  margin-bottom: 0.5em;
}

i[class*="ri-"] {
  font-weight: normal;
  font-size: 0.9rem;
  color: #777;
}

#playMorseInput {
  background: #ddd;
  width: 400px;
  height: 10em;
  padding: 1em;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 45px;
}

#playMorseInput #input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: auto;
}

#playMorseInput #input i {
  font-size: 1.3em;
  display: inline-block;
}

#playMorseInput #input i:hover {
  color: goldenrod;
}

#playMorseInput #input input {
  padding-left: 3px;
  border-radius: 3px;
  border: 1px solid #ddd;
  height: 1.5rem;
  font-size: 0.9em;
  width: auto;
}

#playMorseInput #morseTrans {
  font-size: 0.9em;
  font-family: "Courier", monospace;
}

#legend {
  background: #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

#legend #legend-title {
  margin-left: 2.2em;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.3em;
}

#legend #legend-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 10px;
}

#legend #legend-items .item {
  font-family: "Courier", monospace;
  font-size: 0.85em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 15%;
  margin: 5px;
  padding: 0.3em;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-transition: all 50ms ease-in-out;
  transition: all 50ms ease-in-out;
  background: #ddd;
  border: 0px;
  background: #eee;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
}

#legend #legend-items .item span {
  -webkit-transition: all 50ms ease-in-out;
  transition: all 50ms ease-in-out;
}

#legend #legend-items .item:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  border-color: rgba(112, 128, 144, 0.6);
  background: #ddd;
}

#legend #legend-items .item:active span:first-child {
  background: rgba(112, 128, 144, 0.6);
}

#legend #legend-items .item:hover {
  border-color: rgba(112, 128, 144, 0.6);
  background: #ddd;
}

#legend #legend-items .item:hover span:first-child {
  background: rgba(112, 128, 144, 0.6);
}

#legend #legend-items .item span:first-child {
  display: inline-block;
  padding: 1px;
  width: 1.5em;
  background: #d6d6d6;
  border-radius: 2px;
  font-size: 1.5em;
  -webkit-transition: all 75ms ease-in-out;
  transition: all 75ms ease-in-out;
}

#legend #legend-items .item span:last-child {
  font-weight: bold;
  font-size: 1em;
  display: inline-block;
  padding-left: 5px;
  width: 100%;
}

#morseButton {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100px;
  height: 100px;
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  -webkit-transition: width 500ms ease-out, background 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
  transition: width 500ms ease-out, background 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
  transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out;
  transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
  background: rgba(112, 138, 144, 0.5);
}

#morseButton.active {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}

#morseButton button {
  font-size: 1rem;
  color: transparent;
  width: 50px;
  height: 100px;
  background: rgba(112, 138, 144, 0.5);
  margin: 0px;
  border: 0px;
  -webkit-transition: width 500ms ease-out, background 500ms ease-out, color 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
  transition: width 500ms ease-out, background 500ms ease-out, color 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
  transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out, color 500ms ease-out;
  transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out, color 500ms ease-out, -webkit-transform 40ms ease-out, -webkit-box-shadow 40ms ease-out;
}

#morseButton button.showPaddles {
  color: transparent;
  border-radius: 50px;
  width: 50px;
  height: 100px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
}

#morseButton button#left {
  width: 50%;
  border-radius: 50px 0px 0px 50px;
  display: none;
}

#morseButton button#left.showPaddles {
  border-radius: 50px 0 0 50px;
  display: inline-block;
  margin-right: 7px;
  border-radius: 20px;
}

#morseButton button#right {
  border-radius: 0 50px 50px 0;
  width: 50%;
  display: none;
}

#morseButton button#right.showPaddles {
  border-radius: 0 50px 50px 0;
  display: inline-block;
  margin-left: 7px;
  border-radius: 20px;
}

#morseButton button.active {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
}

#morseButton.showPaddles {
  width: 150px;
  height: 100px;
  background: transparent;
  -webkit-box-shadow: 0px 0px 0px transparent;
          box-shadow: 0px 0px 0px transparent;
}

#morseButtonText {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #aaa;
  font-size: 0.9rem;
}

#paddleText {
  width: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

@-webkit-keyframes hideOverlay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes hideOverlay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0%;
  }
}

#challenge-overlay {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(255, 255, 255, 0.75);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#challenge-overlay.fade {
  -webkit-animation: hideOverlay 1s ease-out forwards;
          animation: hideOverlay 1s ease-out forwards;
}

#challenge-overlay.hide {
  z-index: -100;
}

#challenge-overlay #challengeReady, #challenge-overlay #challengeComplete {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 45%;
  max-width: 600px;
  padding: 1.7em;
  height: 45%;
  background: #eee;
  margin-top: 10rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  border: 3px solid #666;
}

#challenge-overlay #challengeReady #notify-title, #challenge-overlay #challengeComplete #notify-title {
  font-size: 2.5em;
  font-weight: bold;
}

#challenge-overlay #challengeReady #message, #challenge-overlay #challengeComplete #message {
  display: inline-block;
  text-align: center;
  font-size: 1.7em;
}

#challenge-overlay #challengeReady #count, #challenge-overlay #challengeComplete #count {
  font-size: 4.5em;
  font-weight: bold;
}

#challenge-overlay #challengeReady.starting, #challenge-overlay #challengeComplete.starting {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#challenge-overlay #challengeReady button, #challenge-overlay #challengeComplete button {
  background: #eee;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  border-radius: 3px;
  border: 0px;
  padding: 0.3em;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.75em;
  color: #333;
}

#challenge-overlay #challengeReady button#startChallenge, #challenge-overlay #challengeReady button#continue, #challenge-overlay #challengeComplete button#startChallenge, #challenge-overlay #challengeComplete button#continue {
  width: 100%;
  font-size: 1.7em;
  font-weight: bold;
  padding: 0.3em;
  background: #666;
  color: gold;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

#challenge-overlay #challengeReady button#startChallenge:active, #challenge-overlay #challengeReady button#continue:active, #challenge-overlay #challengeComplete button#startChallenge:active, #challenge-overlay #challengeComplete button#continue:active {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

#challenge-overlay #challengeReady button.selected, #challenge-overlay #challengeComplete button.selected {
  -webkit-box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3), inset 0px -1px 1px white;
          box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3), inset 0px -1px 1px white;
}

#challenge-overlay #challengeReady #challengeOptions, #challenge-overlay #challengeComplete #challengeOptions {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker, #challenge-overlay #challengeComplete #challengeOptions .mode-picker {
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div {
  padding: 5px;
  height: 2.4em;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#title, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#title {
  font-weight: bold;
  font-size: 1.4em;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#buttons, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#buttons button, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#buttons button {
  font-size: 1rem;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#info, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.2em;
  height: 100%;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#input, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#input {
  margin-left: 10px;
  margin-top: 0.25em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#challenge-overlay #challengeReady #challengeOptions .mode-picker div#input select, #challenge-overlay #challengeComplete #challengeOptions .mode-picker div#input select {
  height: auto;
  font-size: 1rem;
}

#challenge-header {
  width: 100%;
  padding: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#challenge-header #gameClock {
  font-size: 1.3em;
}

#challenge-header #challengeControls button {
  border: 0px;
  border-radius: 5px;
  padding: 0.3em;
  font-size: 1.2em;
  color: #555;
}

#challenge-header #challengeControls button:hover {
  color: maroon;
}

#challengeWord {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  height: 5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  margin-top: 25px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  font-size: 4rem;
  font-family: "Courier Prime", Courier, monospace;
  font-weight: bold;
  background: #fdfdfd;
  text-transform: uppercase;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

#challengeWord .cLetter {
  padding: 4px;
  margin: 1px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 3rem;
  display: inline-block;
  padding-top: 10px;
  background: transparent;
  -webkit-transition: background 100ms ease-in-out;
  transition: background 100ms ease-in-out;
}

#challengeWord .cLetter.correct {
  background: #5ae65a;
  border-radius: 3px;
}

#challengeWord.correct {
  background: #5ae65a;
}

#morseBufferDisplay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 180px;
  margin-bottom: 20px;
  font-family: "Courier Prime", Courier, monospace;
  position: relative;
}

#morseBufferDisplay #overlay {
  -webkit-box-shadow: inset 20px 0px 20px -5px whitesmoke;
          box-shadow: inset 20px 0px 20px -5px whitesmoke;
  position: absolute;
  display: inline-block;
  top: 20px;
  left: calc(50% - 37.5%);
  width: 600px;
  height: 5rem;
  z-index: 40;
}

#morseBufferDisplay #alphanumeric-container {
  text-align: center;
  max-width: 75%;
  display: inline-block;
  overflow: hidden;
  padding-top: 10px;
}

#morseBufferDisplay #alphanumeric-container #alphanumeric {
  height: 5rem;
  padding-top: 0.45rem;
  font-size: 4rem;
  margin-bottom: 10px;
  border-radius: 3px;
  float: right;
}

#morseBufferDisplay #ditDahs-container {
  text-align: center;
  max-width: 70vw;
  display: inline-block;
  overflow: hidden;
}

#morseBufferDisplay #ditDahs-container #ditDahs {
  height: 50px;
  padding-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 3rem;
  font-family: "Courier", monospace;
  float: right;
}

#morseBufferDisplay #ditDahs-container #ditDahs .ditDah {
  background: #fdfdfd;
  height: 40px;
  width: 30px !important;
  margin-left: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

#challengeBufferDisplay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  font-family: "Courier Prime", Courier, monospace;
  font-weight: bold;
}

#challengeBufferDisplay #ditDahs {
  max-width: 95%;
  height: 60px;
  padding-right: 5px;
  padding-top: 10px;
  margin-bottom: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 3rem;
  font-family: "Courier", monospace;
  text-align: center;
}

#challengeBufferDisplay #ditDahs span {
  float: right;
  padding: 5px;
  -webkit-transition: background 100ms ease-in-out;
  transition: background 100ms ease-in-out;
  background: #fdfdfd;
  height: 40px;
  margin-left: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.space {
  font-size: 20px;
}

#morse-history {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 80%;
  max-width: 500px;
  margin-top: 3em;
}

#morse-history #morseHistory-textbox {
  background: #fff;
  border-radius: 5px;
  min-height: 4em;
  width: 100%;
  padding: 0.5em;
  font-family: Courier;
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow-y: scroll;
}

#morse-history #morseHistory-textbox span {
  margin: 5px;
  background: #fdfdfd;
  height: 1.5rem;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px white;
}

#morse-history #clear {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#morse-history #clear #message {
  font-family: "Roboto", sans-serif;
  padding: 0.3em;
  font-size: 0.9em;
  color: #777;
}

#morse-history #clear #message .ditDah {
  font-family: "Courier", monospace;
}

#morse-history #clear button {
  background: #ededed;
  color: #666;
  font-size: 1em;
  height: 1.5em;
  border: 0px;
  padding: 0.7em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#morse-history #clear button:hover {
  color: maroon;
}

#morseHistory {
  border: 1px solid purple;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 320px;
  position: relative;
  padding-top: 10px;
}

#morseHistory #overlay {
  -webkit-box-shadow: inset 20px 0px 20px #eee, inset -20px 0px 20px #eee, inset 0px -100px 100px #eee;
          box-shadow: inset 20px 0px 20px #eee, inset -20px 0px 20px #eee, inset 0px -100px 100px #eee;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

@media only screen and (max-width: 1024px) {
  #sidebar-content #info {
    padding: 1em;
  }
  #sidebar-content #playerAndLegend {
    padding: 0;
  }
  #sidebar-content #playerAndLegend #legend #legend-items button {
    width: 20%;
  }
  #mainOptions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media only screen and (max-width: 415px) {
  html, body, #root, #main-interface {
    width: 100vw;
    overflow: hidden;
  }
  #header {
    width: 100vw;
    font-size: 1.3em;
    height: 1.5em;
    min-height: 1.5em;
    max-height: 1.5em;
    padding-left: 5px;
  }
  #header #social-links {
    margin-right: 0px;
    font-size: 1rem;
  }
  #header #social-links i {
    font-size: 1.5rem;
    padding-left: 0px;
  }
  #root #main-content {
    height: calc(100vh - 4.1em);
  }
  #root #main-content .sidebar#left {
    top: 2em;
    width: 100vw;
    min-width: 40%;
    max-width: 100vw;
    height: calc(100vh - 4.1em);
  }
  #root #main-content .sidebar#left.hide {
    left: calc(-100% + 40px);
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px transparent;
            box-shadow: 0px 0px 0px transparent;
    z-index: 600;
  }
  #root #main-content .sidebar#left.hide #sidebar-content {
    display: none;
  }
  #root #main-content .sidebar#left #sidebar-content #info {
    padding: 1em;
  }
  #root #main-content .sidebar#left #sidebar-content #playerAndLegend {
    padding: 0px;
  }
  #root #main-content .sidebar#left #sidebar-content #playerAndLegend #legend #legend-items button {
    width: 20%;
  }
  #root #main-content #main-interface {
    max-width: 100vw;
    position: relative;
    left: 100%;
  }
  #root #main-content #main-interface.expandLeft {
    left: 0%;
    width: 100vw;
  }
  #root #main-content #main-interface #mainOptions {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    width: 100vw;
    padding: 3px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 500;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker {
    margin-left: 15px;
    width: 100%;
    max-width: 100vw;
    min-width: 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker div {
    max-width: 100vw;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker div#title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1em;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker div#buttons, #root #main-content #main-interface #mainOptions div .mode-picker div#input {
    font-size: 1em;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker button, #root #main-content #main-interface #mainOptions div .mode-picker #input button {
    font-size: 0.8em;
  }
  #root #main-content #main-interface #mainOptions div .mode-picker #input {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady, #root #main-content #main-interface #challenge-overlay #challengeComplete {
    height: 60%;
    max-height: 600px;
    padding: 0px;
    width: 90%;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady #notify-title, #root #main-content #main-interface #challenge-overlay #challengeComplete #notify-title {
    font-size: 1.5em;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady div .mode-picker, #root #main-content #main-interface #challenge-overlay #challengeComplete div .mode-picker {
    width: 95%;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady div .mode-picker #title, #root #main-content #main-interface #challenge-overlay #challengeComplete div .mode-picker #title {
    font-size: 1em;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady div .mode-picker #info, #root #main-content #main-interface #challenge-overlay #challengeReady div .mode-picker #input, #root #main-content #main-interface #challenge-overlay #challengeReady div .mode-picker #buttons, #root #main-content #main-interface #challenge-overlay #challengeComplete div .mode-picker #info, #root #main-content #main-interface #challenge-overlay #challengeComplete div .mode-picker #input, #root #main-content #main-interface #challenge-overlay #challengeComplete div .mode-picker #buttons {
    font-size: 1em;
  }
  #root #main-content #main-interface #challenge-overlay #challengeReady #startChallenge, #root #main-content #main-interface #challenge-overlay #challengeComplete #startChallenge {
    font-size: 1.3em;
    line-height: 2em;
  }
  #root #main-content #main-interface #morseBufferDisplay {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-bottom: 0px;
    margin-top: 0px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #root #main-content #main-interface #morseBufferDisplay #alphanumeric-container {
    min-height: 3.5rem;
    max-height: 3.5rem;
    margin-bottom: 0px;
    padding: 0px;
  }
  #root #main-content #main-interface #morseBufferDisplay #alphanumeric-container #alphanumeric {
    min-height: 3.5rem;
    font-size: 3rem;
  }
  #root #main-content #main-interface #morseBufferDisplay #ditDahs-container {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 2.3rem;
    max-height: 2.3rem;
  }
  #root #main-content #main-interface #morseBufferDisplay #ditDahs-container #ditDahs {
    min-height: 2rem;
    max-height: 2rem;
  }
  #root #main-content #main-interface #morseBufferDisplay #ditDahs-container #ditDahs .ditDah {
    height: 2rem;
    min-width: 1.7rem;
    max-width: 1.7rem;
    font-size: 2rem;
  }
  #root #main-content #main-interface #morse-history {
    margin-top: 0px;
  }
  #root #main-content #main-interface #morse-history #morseHistory-textbox {
    background: #fff;
    min-height: 2em;
    max-height: 2em;
  }
  #root #main-content #main-interface #morse-history #clear button {
    padding: 0px;
    padding-left: 3px;
    padding-right: 3px;
  }
  #root #main-content #main-interface #challenge-header {
    padding: 5px;
  }
  #root #main-content #main-interface #challenge-header #gameClock {
    font-size: 0.8em;
  }
  #root #main-content #main-interface #challenge-header #challengeControls button {
    font-size: 1em;
  }
  #root #main-content #main-interface #challengeWord {
    margin-top: 0;
    height: 4rem;
    font-size: 3rem;
  }
  #root #main-content #main-interface #challengeWord span {
    line-height: 2rem;
  }
  #root #main-content #main-interface #morseButton {
    margin-top: 0;
  }
}
