@import url('https://fonts.googleapis.com/css?family=Courier+Prime:700|Roboto:700|Roboto&display=swap');
$main-font: 'Roboto', sans-serif;
$buffer-font: 'Courier Prime', Courier, monospace;
$ditDah-font: 'Courier', monospace;
$main-bg-color-light: #eee;
$main-font-color-light: #333;
$correct-bg-color: rgba(90,230,90,1);
$main-box-shadow-light: 0px 2px 2px rgba(0, 0, 0, 0.35), 0px -1px 1px rgba(255, 255, 255, 1);
$main-box-shadow-light-selected: inset 0px 2px 2px rgba(0, 0, 0, 0.3), inset 0px -1px 1px rgba(255, 255, 255, 1);
$main-border-radius: 3px;


* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    cursor: default;
    touch-action: manipulation; // Disable double-tap to zoom on mobile devices
}
button {
    font-family: $main-font;
}
html, body {
    height: 100%;
    width: 100vw;
    background: $main-bg-color-light;
}
#root {
    // border: 3px solid green;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#header {
    min-height: 50px;
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    background: #333;
    font-family: $main-font;
    color: $main-bg-color-light;
    z-index: 1000;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.45);
    font-size: 2.5em;

    #title {
        font-weight: bold;
        text-transform: uppercase;

    }
    #social-links {
        // border: 1px solid red;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #999;
        margin-right: 10px;
        i {
            color: #ccc;
            padding-left: 5px;
            padding-right: 5px;
            font-size: 2rem;
            &:hover {
                color: gold;
            }
        }
    }
}
#main-content {
    // border: 1px solid red;
    display: flex;
    height: 95vh;
    min-width: fit-content;
    width: 100vw;
    align-items: center;
    align-self: center;
    overflow-x: hidden;
    position: relative;
    left: 0px;

    .sidebar#left {
        // border: 1px solid blue;
        background: $main-bg-color-light;
        box-shadow: 3px 0px 3px rgba(0,0,0,0.25);
        
        font-family: 'Roboto', sans-serif;
        line-height: 1.5em;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        
        width: 40%;
        height: calc(100% - 5.1em);
        max-width: 100%;
        overflow-y: scroll;

        position: fixed;
        top: 50px;
        left: 0px;

        z-index: 100;
        
        transition: all 500ms ease-in-out;

        &.hide {
            left: calc(-40% + 40px);
            top: 50px;
            overflow-y: hidden;

            #sidebar-container {
                #sidebar-content #info {
                    opacity: 0;
                    overflow-y: hidden;
                }
                #info-icon {
                    top: 0px;
                    transform: rotate(180deg);
                }
            }
        }

        #sidebar-container {
            height: 100%;
            width: 100%;

            .navbar {
                // border-bottom: 1px solid #ccc;
                font-size: 1em;
                line-height: 1em;
                width: 100%;
                height: 35px;
                display: flex;
                justify-content: center;
                text-transform: uppercase;
                font-weight: 550;
                
                .nav-item {
                    // border: 1px solid black;
                    padding: 10px;
                    width: 30%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    transition: all 150ms ease-in-out;
                    border-bottom: 2px solid transparent;
    
                    &:hover {
                        background: rgba(0, 0, 0, 0.1);
                        border-color: #999;
                    }
                    &.selected {
                        border-color: rgb(80, 128, 144);
                    }
                    &#nav-play {
                        display: none;
                    }
                }
            }
    
            #info-icon {
                width: fit-content;
                height: fit-content;
                position: absolute;
                top: 7px;
                right: 6px;
                z-index: 1010;
                transition: all 500ms ease-in-out;
                &:hover {
                    i {
                        color: goldenrod;
                    }
                }
                i {
                    color: #333;
                    font-size: 1.7rem;
                    transition: all 100ms ease-in-out;
                }
            }
    
            #sidebar-content {
                // border: 1px solid cyan;
                display: flex;
                justify-content: center;
                align-self: center;
                
                #playerAndLegend {
                    // border: 1px solid fuchsia;
                    padding: 2em;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    overflow-y: scroll;
                    #legend, #playMorseInput, span#note {
                        margin-bottom: 1em;
                    }
                    span#note {
                        font-family: $ditDah-font;
                        color: #555;
                        display: inline-block;
                        width: 65%;
                        text-align: center;
                    }
                }

                #info {
                    // border: 1px solid red;
                    height: fit-content;
                    padding: 2.5em;
                    font-family: $main-font;
                    font-size: 1.2rem;
                    line-height: 1.5em;
                    opacity: 1;
                    
                    transition: all 500ms ease-in-out;
    
                    h1, h2 {
                        margin-bottom: 0.3em;
                    }
                    .bold {
                        display: inline-block;
                        font-size: 1.15em;
                        font-weight: bold;
                        margin-bottom: 0.1em;
                    }
                    p {
                        margin-bottom: 2em;
                        span.ditdah {
                            font-family: $ditDah-font;
                        }
                    }
                    img {
                        width: 50%;
                        image-rendering: optimizeSpeed;
                    }
                    a:visited {
                        color: #333;
                    }
                    a:hover, i:hover {
                        color: goldenrod;
                    }
                    i {
                        font-size: 1.2rem;
                        line-height: 1rem;
                    }
                }
            }
        }
    }
    #main-interface {
        // border: 1px solid red;
        
        background: rgb(245, 245, 245);
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 60%;
        position: relative;
        left: 40%;
        transition: all 500ms ease-in-out;

        &.expandLeft {
            left: 40px;
            width: calc(100% - 40px);
        }

        #mainOptions {
            // border: 1px solid red;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            overflow-x: scroll;
            width: 100%;
            padding: 10px;
            background: rgba(112, 128, 144,0.3);
            box-shadow: inset 0px -2px 2px rgba(0,0,0,0.1);

            font-family: $main-font;
            z-index: 500;

            #options-left, #options-right {
                // border: 1px solid cyan;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: fit-content;

                .mode-picker {
                    // border: 1px solid green;
                    width: fit-content;
                    display: flex;
                    align-content: flex-start;
                    justify-content: flex-start;
                    
                    div {
                        // border: 1px solid blue;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 3px;
                        height: 100%;
                        margin-bottom: 5px;
                    }
                    #title {
                        // border: 1px solid red;
                        justify-content: flex-end;
                        width: 10em;
                        font-weight: bold;
                        font-size: 1.15em;
                        span#range {
                            display: inline-block;
                            padding-left: 5px;
                            font-size: 0.8em;
                        }
                    }
                    #buttons {
                        // border: 1px solid blue;
                        justify-content: space-evenly;
                    }
                    #input {
                        input {
                            width: 50px;
                            height: 1.5rem;
                            border: 1px solid #ddd;
                            appearance: textfield;
                            text-align: center;
                            border-radius: 3px;
                            font-size: 0.8rem;
                        }
                        select {
                            height: 1.4rem;
                        }
                        button {
                            width: 1.4em;
                            height: 1.4em;
                            border-radius: 3px;
                            font-size: 1em;
                            i {
                                position: relative;
                                left: -6px;
                                top: -2px;
                                font-size: 1.1em;
                                font-weight: bold;
                            }
                        }
                    }
                    button {
                        background: $main-bg-color-light;
                        box-shadow: $main-box-shadow-light;
                        border-radius: $main-border-radius;
                        border: 0px;
                        padding: 0.2em;
                        padding-left: 0.5em;
                        padding-right: 0.5em;
                        margin-left: 10px;
                        margin-right: 10px;
            
                        font-size: 0.9em;
                        color: $main-font-color-light;
            
                        &.selected {
                            box-shadow: $main-box-shadow-light-selected;
                        }
                    }
                }
            }

            #options-left .mode-picker {
                #title {
                    width: 5em;
                }
            }
        }
    }
}
#footer {
    width: 100%;
    height: 2em;
    padding: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333;
    font-family: $main-font;
    font-size: 1em;
    color: $main-bg-color-light;
    z-index: 1000;

    i {
        font-size: 1.3em;
        color: #ccc;
        &:hover {
            color: gold;
        }
    }
}
h2 {
    margin-bottom: 0.5em;
}
i[class*="ri-"] {
    font-weight: normal;
    font-size: 0.9rem;
    color: #777;
}


#playMorseInput {
    // border: 1px solid #bbb;
    background: #ddd;
    width: 400px;
    height: 10em;
    padding: 1em;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
    
    #input {
        // border: 1px solid red;
        display: flex;
        align-items: center;
        width: auto;

        i {
            // background: blue;
            font-size: 1.3em;
            display: inline-block;
            
            &:hover {
                color: goldenrod;
            }
        }
        input {
            padding-left: 3px;
            border-radius: 3px;
            border: 1px solid #ddd;
            height: 1.5rem;
            font-size: 0.9em;
            width: auto;
        }
    }

    #morseTrans {
        font-size: 0.9em;
        font-family: $ditDah-font;
    }
}

#legend {
    // border: 1px solid orange;
    background: $main-bg-color-light;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    #legend-title {
        // border: 1px solid purple;
        margin-left: 2.2em;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 0.3em;
    }
    #legend-items {
        // border: 1px solid blue;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        margin-bottom: 10px;

        .item {
            font-family: $ditDah-font;
            font-size: 0.85em;
            display: flex;
            align-items: center;
            width: 15%;

            margin: 5px;
            padding: 0.3em;
            border: 1px solid #ccc;
            border-radius: 3px;
            transition: all 50ms ease-in-out;
            background: #ddd;

            border: 0px;
            background: $main-bg-color-light;
            box-shadow: $main-box-shadow-light;
            span {
                transition: all 50ms ease-in-out;
            }
            &:active {
                transform: scale(0.95);
                border-color: rgba(112, 128, 144,0.6);
                background: #ddd;
                span:first-child {
                    background: rgba(112, 128, 144,0.6);
                }
            }
            &:hover {
                border-color: rgba(112, 128, 144,0.6);
                background: #ddd;
                span:first-child {
                    background: rgba(112, 128, 144,0.6);
                }
            }
            
            span:first-child {
                display: inline-block;
                padding: 1px;
                width: 1.5em;
                background: #d6d6d6;
                border-radius: 2px;
                font-size: 1.5em;

                transition: all 75ms ease-in-out;
            }
            span:last-child {
                // background: #08c;
                // font-family: $ditDah-font;
                font-weight: bold;
                font-size: 1em;
                display: inline-block;
                padding-left: 5px;
                width: 100%;
            }
        }
    }
}

$button-diameter: 100px;
$button-radius: 50px;
$morse-button-color: rgba(112, 138, 144,0.5);

#morseButton {
    user-select: none;
    width: $button-diameter;
    height: $button-diameter;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 50%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $main-box-shadow-light;
    transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out;
    background: $morse-button-color;

    &.active {
        transform: translateY(3px);
        box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
    }
    
    button {
        font-size: 1rem;
        color: transparent;
        width: $button-radius;
        height: $button-diameter;
        background: $morse-button-color;
        margin: 0px;
        border: 0px;
        transition: transform 40ms ease-out, box-shadow 40ms ease-out, width 500ms ease-out, background 500ms ease-out, color 500ms ease-out;
        
        &.showPaddles {
            color: transparent;
            border-radius: $button-radius;
            width: $button-radius;
            height: $button-diameter;
            box-shadow: $main-box-shadow-light;
        }
        &#left {
            // background: red;
            width: 50%;
            border-radius: $button-radius 0px 0px $button-radius;
            display: none;

            &.showPaddles{
                border-radius: $button-radius 0 0 $button-radius;
                display: inline-block;
                margin-right: 7px;
                border-radius: 20px;
            }
        }
        &#right {
            // background: blue;
            border-radius: 0 $button-radius $button-radius 0;
            width: 50%;
            display: none;

            &.showPaddles{
                border-radius: 0 $button-radius $button-radius 0;
                display: inline-block;
                margin-left: 7px;
                border-radius: 20px;
            }
        }
        &.active {
            transform: translateY(3px);
            box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
        }
    }
    
    &.showPaddles {
        width: 150px;
        height: $button-diameter;
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
    }
}
#morseButtonText {
    font-family: $main-font;
    font-weight: bold;
    color: #aaa;
    font-size: 0.9rem;
}
#paddleText {
    width: 140px;
    display: flex;
    justify-content: space-around;
}

@keyframes hideOverlay {
    0% { 
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#challenge-overlay {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: rgba(255,255,255,0.75);
    display: flex;
    justify-content: center;
    &.fade {
        animation: hideOverlay 1s ease-out forwards;
    }
    &.hide {
        z-index: -100;
    }
    
    #challengeReady, #challengeComplete {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        width: 45%;
        max-width: 600px;
        padding: 1.7em;
        height: 35%;
        background: $main-bg-color-light;
        margin-top: 10rem;
        border-radius: 5px;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.5);
        border: 3px solid #666;

        #notify-title {
            font-size: 2.5em;
            font-weight: bold;
        }
        #message {
            display: inline-block;
            text-align: center;
            font-size: 1.7em;
        }
        #count {
            font-size: 4.5em;
            font-weight: bold;
        }
        &.starting {
            justify-content: center;
        }
        button {
            background: $main-bg-color-light;
            box-shadow: $main-box-shadow-light;
            border-radius: $main-border-radius;
            border: 0px;
            padding: 0.3em;
            margin-left: 10px;
            margin-right: 10px;
    
            font-size: 0.75em;
            color: $main-font-color-light;
            
            &#startChallenge, &#continue {
                width: 100%;
                font-size: 1.7em;
                font-weight: bold;
                padding: 0.3em;
                background: #666;
                color: gold;
                text-transform: uppercase;
                letter-spacing: 0.3rem;
                
                &:active {
                    transform: translateY(3px);
                    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
                }
            }
            &.selected {
                box-shadow: $main-box-shadow-light-selected;
            }
        }
        #challengeOptions {
            // border: 1px solid blue;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .mode-picker {
                // border: 1px solid green;
                width: 90%;
                display: flex;
                align-content: center;
                justify-content: flex-start;

                div {
                    padding: 5px;
                    height: 2.4em;
                }
        
                div#title {
                    // border: 1px solid red;
                    font-weight: bold;
                    font-size: 1.4em;
                    height: 100%;
                    display: flex;
                    padding-left: 0px;
                    justify-content: flex-start;
                    align-items: center;
                }
                div#buttons {
                    // border: 1px solid blue;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;

                    button {
                        font-size: 1rem;
                    }
                }
                div#info {
                    // border: 1px solid black;
                    display: flex;
                    align-items: center;
                    font-size: 1.2em;
                    height: 100%;
                }
                div#input {
                    margin-left: 10px;
                    margin-top: 0.25em;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    select {
                        height: auto;
                        // padding: 5px;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
        
}

#challenge-header {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;

    #gameClock {
        font-size: 1.3em;
    }
    #challengeControls {
        button {    
            border: 0px;
            border-radius: 5px;
            padding: 0.3em;
            font-size: 1.2em;
            color: #555;
            &:hover {
                color: maroon;
            }
        }
    }
}

#challengeWord {
    // border: 1px solid green;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    height: 5rem;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 25px;

    border-radius: $main-border-radius;
    box-shadow: $main-box-shadow-light;
    
    font-size: 4rem;
    font-family: $buffer-font;
    font-weight: bold;

    background: #fdfdfd;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;
    
    .cLetter {
        padding: 4px;
        margin: 1px;
        display: inline-flex;
        align-items: center;
        line-height: 3rem;
        display: inline-block;
        padding-top: 10px;
        background: transparent;
        transition: background 100ms ease-in-out; //, opacity 100ms ease-in-out;
        
        &.correct {
            background: $correct-bg-color;
            border-radius: $main-border-radius;
        }
    }
    &.correct {
        background: $correct-bg-color;
    }   
}

#morseBufferDisplay {
    // border: 1px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    height: 180px;
    margin-bottom: 20px;
    font-family: $buffer-font;
    position: relative;

    #overlay {
        // background: blue;
        box-shadow: inset 20px 0px 20px -5px rgb(245, 245, 245); //this
        position: absolute;
        display: inline-block;
        top:20px;
        left: calc(50% - 37.5%);
        width: 600px;
        height: 5rem;
        z-index: 40;
    }

    #alphanumeric-container {
        // border: 1px solid red;
        text-align: center;
        max-width: 75%;
        display: inline-block;
        overflow: hidden;
        padding-top: 10px;
        
        #alphanumeric {
            // border: 1px solid blue;

            height: 5rem;
            padding-top: 0.45rem;
            font-size:  4rem;
            margin-bottom: 10px;
            border-radius: $main-border-radius;
            // box-shadow: $main-box-shadow-light;
            float: right;
        }
    }
    #ditDahs-container {
        // border: 1px solid red;
        text-align: center;
        max-width: 70vw;
        display: inline-block;
        overflow: hidden;

        #ditDahs {
            // border: 1px solid purple;
            height: 50px;
            padding-right: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 3rem;
            font-family: $ditDah-font;
            float: right;
    
            .ditDah {
                background: #fdfdfd;
                height: 40px;
                width: 30px !important;
                margin-left: 3px;
                border-radius: $main-border-radius;
                box-shadow: $main-box-shadow-light;
                display: flex;
                justify-content: center;
                align-items: center;
                
                transition: all 100ms ease-in-out;
            }
            
        }
    }
}

#challengeBufferDisplay {
    // border: 1px solid green;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: $buffer-font;
    font-weight: bold;

    #ditDahs {
        // border: 1px solid purple;
        max-width: 95%;
        height: 60px;
        padding-right: 5px;
        padding-top: 10px;
        margin-bottom: 10px;
        display: inline-flex;
        overflow: hidden;
        flex-direction: row;
        justify-content: center;
        font-size: 3rem;
        font-family: $ditDah-font;
        text-align: center;
        
        span {
            float: right;
            padding: 5px;
            transition: background 100ms ease-in-out; 
            background: #fdfdfd;
            height: 40px;
            margin-left: 3px;
            border-radius: $main-border-radius;
            box-shadow: $main-box-shadow-light;
            display: flex;
            justify-content: center;
            align-items: center;
            
            transition: all 100ms ease-in-out;
        }
    }
}

.space {
    font-size: 20px;
}

#morse-history {
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 80%;
    max-width: 500px;
    margin-top: 3em;
    #morseHistory-textbox {
        // border: 1px solid cyan;
        background: #fff;
        border-radius: 5px;
        min-height: 4em;
        width: 100%;
        padding: 0.5em;
        font-family: Courier;
        font-size: 1.5rem;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow-y: scroll;
    
        span {
            margin: 5px;
            background: #fdfdfd;
            height: 1.5rem;
            padding: 4px;
            padding-top: 0px;
            padding-bottom: 0px;
            border-radius: $main-border-radius;
            box-shadow: $main-box-shadow-light;
        }
    }
    #clear {
        width: 100%;
        display: flex;
        height: fit-content;
        justify-content: space-between;
        #message {
            font-family: $main-font;
            padding: 0.3em;
            font-size: 0.9em;
            color: #777;
            .ditDah {
                font-family: $ditDah-font;
            }
        }
        button {
            background: #ededed;
            color: #666;
            font-size: 1em;
            height: 1.5em;
            border: 0px;
            padding: 0.7em;
            display: flex;
            align-items: center;
            &:hover {
                color: maroon;
            }
        }
    }
}

#morseHistory {
    border: 1px solid purple;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 320px;
    position: relative;
    padding-top: 10px;

    #overlay {
        // background: blue;
        box-shadow: inset 20px 0px 20px $main-bg-color-light, inset -20px 0px 20px $main-bg-color-light, inset 0px -100px 100px $main-bg-color-light;
        position: absolute;
        display: inline-block;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }
}

@media only screen and (max-width: 1024px) {

    #sidebar-content {
        #info {
            padding: 1em;
        }
        #playerAndLegend {
            padding: 0;
            #legend #legend-items button {
                width: 20%;
            }
        }
    }
    #mainOptions {
        flex-direction: column;
        align-items: center;
    }
}
@media only screen and (max-width: 415px) {

    html, body, #root, #main-interface {
        width: 100vw;
        overflow: hidden;
    }
    #header {
        width: 100vw;
        font-size: 1.3em;
        height: 1.5em;
        min-height: 1.5em;
        max-height: 1.5em;
        padding-left: 5px;

        #social-links {
            margin-right: 0px;
            font-size: 1rem;
            i {
                font-size: 1.5rem;
                padding-left: 0px;
            }
        }
    }
    #root {
        #main-content {
            height: calc(100vh - 4.1em);

            .sidebar#left {
                top: 2em;
                width: 100vw;
                min-width: 40%;
                max-width: 100vw;
                height: calc(100vh - 4.1em);

                &.hide {
                    left: calc(-100% + 40px);
                    background: transparent;
                    box-shadow: 0px 0px 0px transparent;
                    z-index: 600;
                    #sidebar-content {
                        display: none;
                    }
                }

                #sidebar-content {
                    #info {
                        padding: 1em;
                    }
                    #playerAndLegend {
                        // border: 1px solid blue;
                        padding: 0px;
                        #legend #legend-items button {
                            width: 20%;
                        }
                    }
                }
            }

            #main-interface {
                // border: 1px solid red;
                max-width: 100vw;
                position: relative;
                left: 100%;
                &.expandLeft {
                    left: 0%;
                    width: 100vw;
                }

                #mainOptions {
                    height: fit-content;
                    min-height: fit-content;
                    width: 100vw;
                    padding: 3px;
                    flex-direction: column;
                    align-items: center;
                    z-index: 500;
                    div .mode-picker {
                        // border: 1px solid red;
                        margin-left: 15px;
                        width: 100%;
                        max-width: 100vw;
                        min-width: 50px;
                        height: fit-content;
                        div {
                            &#title {
                                // border: 1px solid red;
                                width: fit-content;
                                font-size: 1em;
                            }
                            &#buttons, &#input {
                                font-size: 1em;
                            }
                            // border: 1px solid green;
                            max-width: 100vw;
                        }
                        button, #input button {
                            font-size: 0.8em;
                        }
                        #input {
                            width: fit-content;
                        }
                    }
                }

                #challenge-overlay {
                    #challengeReady, #challengeComplete {
                        height: 60%;
                        max-height: 600px;
                        padding: 0px;
                        width: 90%;
                        #notify-title {
                            font-size: 1.5em;
                        }
                        div .mode-picker {
                            // border: 1px solid orange;
                            width: 95%;
                            #title {
                                // border: 1px solid red;
                                font-size: 1em;
                            }
                            #info, #input, #buttons {
                                font-size: 1em;
                            }
                        }
                        #startChallenge {
                            font-size: 1.3em;
                            line-height: 2em;
                        }
                        
                    }
                }

                #morseBufferDisplay {
                    // background: green;
                    // border: 1px solid black;
                    justify-content: flex-end;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    height: fit-content;
                    #alphanumeric-container {
                        // background: blue;
                        // border: 1px solid green;
                        min-height: 3.5rem;
                        max-height: 3.5rem;
                        margin-bottom: 0px;
                        padding: 0px;
                        #alphanumeric {
                            // background: red;
                            min-height: 3.5rem;
                            font-size: 3rem;
                        }
                    }
                    #ditDahs-container {
                        // border: 1px solid blue;
                        margin-top: 0;
                        margin-bottom: 0;
                        min-height: 2.3rem;
                        max-height: 2.3rem;
                        #ditDahs {
                            min-height: 2rem;
                            max-height: 2rem;
                            .ditDah {
                                // background: red;
                                height: 2rem;
                                min-width: 1.7rem;
                                max-width: 1.7rem;
                                font-size: 2rem;
                            }
                        }
                    }
                }
                #morse-history {
                    // border: 2px solid cyan;
                    margin-top: 0px;

                    #morseHistory-textbox {
                        // border: 2px solid cyan;
                        background: #fff;
                        min-height: 2em;
                        max-height: 2em;
                    }
                    #clear button {
                        padding: 0px;
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }
                #challenge-header {
                    padding: 5px;
                    #gameClock {
                        font-size: 0.8em;
                    }
                    #challengeControls button {
                        font-size: 1em;
                    }
                }

                #challengeWord {
                    margin-top: 0;
                    height: 4rem;
                    font-size: 3rem;
                    span {
                        line-height: 2rem;
                    }
                }
                #morseButton {
                    margin-top: 0;
                }
            }

        }
    }
}